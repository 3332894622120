import { ListHeaderItem } from '@/components/common/list';
import { account } from '@/data';

const headers: ListHeaderItem[] = [{
    name: 'OrderNumber',
    label: WordList.OrderOrderNumber
}, {
    name: 'Installer',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Projects',
    label: WordList.ProperAllTextProject,
    type: 'customize'
}, {
    name: 'PayerType',
    label: WordList.PayerRole,
    type: 'customize'
}, {
    name: 'Payer',
    label: WordList.ProperAllTextPayer
}, {
    name: 'Type',
    label: WordList.TabelKeyInHtmlType
}, {
    name: 'TotalPrice',
    label: WordList.OrderAmount
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus
}, {
    name: 'CreateTime',
    label: WordList.TabelMessageBoxCreateTime
}];
const payTypeOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '0',
    label: WordList.ProperAllTextFamily
}, {
    value: '1',
    label: WordList.ProperAllCommunity
}];
const typeOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '1',
    label: WordList.ProperAllTextActivate
}, {
    value: '2',
    label: WordList.ProperAllTextSubscription
}, {
    value: '3',
    label: WordList.AdditionalApp
}, {
    value: '5',
    label: WordList.ProperAllTextFeature
}];
const statusOptions = [{
    value: 'all',
    label: WordList.SelectAll
}, {
    value: '0',
    label: WordList.ProperAllTextProcessing
}, {
    value: '1',
    label: WordList.ProperAllTextSuccessed
}, {
    value: '2',
    label: WordList.PropertyAllTextFailed
}, {
    value: '3',
    label: WordList.ProperAllTextTimeOut
}, {
    value: '5',
    label: WordList.TabelFootCancel
}, {
    value: '6',
    label: WordList.ProperAllTextSystemProcessing
}];

const checkNameForProject = (data: Array<{ ID: string; Name: string; Grade: string }>) => {
    const checkResult = [...data];
    data.forEach((outerItem, outerIndex) => {
        data.forEach((innerItem, innerIndex) => {
            if (innerItem.Name === outerItem.Name && innerItem.Grade !== outerItem.Grade) {
                checkResult[outerIndex].Name = `${outerItem.Name}(${Number(outerItem.Grade) === account.officeRole
                    ? WordList.ProperAllOffice : WordList.ProperAllCommunity})`;
                checkResult[innerIndex].Name = `${innerItem.Name}(${Number(innerItem.Grade) === account.officeRole
                    ? WordList.ProperAllOffice : WordList.ProperAllCommunity})`;
            }
        });
    });
    return checkResult;
};

export default null;
export {
    headers,
    payTypeOptions,
    typeOptions,
    statusOptions,
    checkNameForProject
};