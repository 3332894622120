
import {
    computed, defineComponent, watch, Ref, ref, PropType
} from 'vue';
import router from '@/router';
import { superBaseRouter, superSubRouter } from '@/router/data';

import secondaryNav from '@/components/common/secondary-nav/index.vue';
import {
    ListHeaderItem, listPagination, ListActionItem, ActionType
} from '@/components/common/list';
import addButton from '@/components/common/add-button/index.vue';
import setDidstibutorDialog from '@/components/view/super/set-distributor-dialog';
import kitDialog from '@/components/view/super/kit-dialog';
import { RequestData } from '@/components/common/list/util/all-interface';
import { accountType } from '@/data';
import { userForSuperApi } from '@/api';
import { checkNameForProject } from '@/views/super/payment/util';
import {
    getMenus, getUserPageData, UserType, SearchKey,
    addDistributor, editDistributor, isShowDisDialog, formData, disDialogOperaType,
    changePath, getInitPlan
} from './util';

export default defineComponent({
    components: {
        secondaryNav,
        listPagination,
        setDidstibutorDialog,
        addButton,
        kitDialog
    },
    props: {
        type: {
            type: String as PropType<UserType>,
            default: 'distributor'
        }
    },
    setup(props) {
        const isShowKit = ref(false);
        const secondMenus = getMenus(superBaseRouter);
        // list 所需数据
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const searchKeyList: Ref<SearchKey> = ref([]);
        const defaultAction: Ref<Array<ActionType>> = ref([]);
        const deleteUrl = ref('');
        const action: Ref<Array<ListActionItem>> = ref([]);
        const updateToList = ref(0);
        const activeNavItem = computed(() => props.type);
        const userPageData = getUserPageData();
        watch(activeNavItem, () => {
            const pageData = userPageData[activeNavItem.value];
            headers.value = pageData.header;
            listRequestData.value = pageData.requestData;
            searchKeyList.value = pageData.searchKey;
            defaultAction.value = pageData.defaultAction;
            deleteUrl.value = pageData.deleteUrl;

            if (activeNavItem.value === 'distributor') {
                action.value = [{
                    type: 'device',
                    event: 'goToDevice',
                    class: 'el-icon-my-devices color-main',
                    title: WordList.TabelUpdateBoxDevice
                }];
                getInitPlan();
            } else {
                action.value = [];
            }
            // nextTick(() => { updateToList.value += 1; });
        }, {
            immediate: true
        });

        const goMacLibrary = (id: string | false = false) => {
            router.push(`/${superBaseRouter}/${superSubRouter.macLibrary}${id === false ? '' : `?id=${id}`}`);
        };

        // 6.2新增installer详情
        const goInfo = (value: { ID: string; UUID: string }) => {
            if (activeNavItem.value === 'endUser') {
                router.push(`/${superBaseRouter}/${superSubRouter.userInfo}?id=${value.ID}`);
            } else if (activeNavItem.value === 'installer') {
                router.push(`/${superBaseRouter}/${superSubRouter.insInfo}`);
                localStorage.setItem('installerInfo', JSON.stringify(value));
            } else if (activeNavItem.value === 'officeUser') {
                router.push(`/${superBaseRouter}/${superSubRouter.officeInfo}?id=${value.ID}`);
            } else if (activeNavItem.value === 'property') {
                router.push(`/${superBaseRouter}/${superSubRouter.propertyInfo}?id=${encodeURIComponent(value.UUID)}`);
            }
        };

        const closeDisDialog = () => {
            isShowDisDialog.value = false;
        };

        // 兼容新接口参数名searchKey
        const searchKey = computed({
            get: () => (props.type === 'officeUser' || props.type === 'property'
                ? listRequestData.value.param.searchKey : listRequestData.value.param.searchKey),
            set: (val) => {
                if (props.type === 'officeUser' || props.type === 'property') {
                    listRequestData.value.param.searchKey = val;
                } else {
                    listRequestData.value.param.searchKey = val;
                }
            }
        });

        const searchValue = computed({
            get: () => (props.type === 'officeUser' ? listRequestData.value.param.searchValue : listRequestData.value.param.searchValue),
            set: (val) => {
                if (props.type === 'officeUser') {
                    listRequestData.value.param.searchValue = val;
                } else {
                    listRequestData.value.param.searchValue = val;
                }
            }
        });

        const installerOptions: Ref<accountType.GetAllInstallerCommunity> = ref([]);
        const communityOptions: Ref<Array<{ ID: string; Name: string; Grade: string }>> = ref([]);
        userForSuperApi.getAllInstallerCommunity((data: accountType.GetAllInstallerCommunity) => {
            installerOptions.value = data;
        });
        const changeInstaller = (ID: string) => {
            listRequestData.value.param.ProjectId = 'all';
            installerOptions.value.forEach((item) => {
                if (item.ID === ID) {
                    communityOptions.value = checkNameForProject([...item.Community, ...item.Office]);
                }
            });
        };

        return {
            isShowKit,
            secondMenus,
            activeNavItem,
            changePath,
            listRequestData,
            searchKeyList,
            updateToList,
            defaultAction,
            action,
            deleteUrl,
            headers,
            addDistributor,
            editDistributor,
            isShowDisDialog,
            formData,
            disDialogOperaType,
            goMacLibrary,
            goInfo,
            closeDisDialog,
            searchKey,
            searchValue,
            installerOptions,
            communityOptions,
            changeInstaller
        };
    }
});
