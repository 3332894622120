import { ref, Ref } from 'vue';
import { ListHeaderItem, ActionType } from '@/components/common/list';
import { RequestData } from '@/components/common/list/util/all-interface';
import { FormData } from '@/components/view/super/set-distributor-dialog';
import { ProjectFeatureType, PlanType, FeeType } from '@/components/view/super/feature-plan-select/all.type';
import { timeZone, featurePlan } from '@/data';
import { deepCopyObject } from '@/methods/basicFun';
import router from '@/router/index';

export default null;

// 7.0 新增办公人员板块
function getMenus(basicRouter: string) {
    return [{
        label: WordList.ProperAllTextRoleDistributor,
        key: 'distributor',
        path: `/${basicRouter}/user?type=distributor`
    }, {
        label: WordList.ProperAllTextInstaller,
        key: 'installer',
        path: `/${basicRouter}/user?type=installer`
    }, {
        label: WordList.ProperAllTextResidentialUser,
        key: 'endUser',
        path: `/${basicRouter}/user?type=endUser`
    }, {
        label: WordList.ProperAllTextOfficeUser,
        key: 'officeUser',
        path: `/${basicRouter}/user?type=officeUser`
    }, {
        label: WordList.TabelUserInHtmlRoleManage,
        key: 'property',
        path: `/${basicRouter}/user?type=property`
    }];
}

function isValidFormData(formData: FormData | false): formData is FormData {
    return formData !== false;
}

// 获取预设方案、费用
const projectFeatureData: Ref< ProjectFeatureType > = ref({
    community: {
        Fee: [],
        Plan: []
    },
    office: {
        Fee: [],
        Plan: []
    }
});
const projectFeatureOptions: Ref< ProjectFeatureType > = ref({
    community: {
        Fee: [],
        Plan: []
    },
    office: {
        Fee: [],
        Plan: []
    }
});
function getInitPlan() {
    featurePlan.getFeaturePlan((res: Array<PlanType>) => {
        projectFeatureData.value.community.Plan = res.slice(0, 2);
        projectFeatureOptions.value.community.Plan = res;
    });
    featurePlan.getAllFeaturePlanFee((res: {
        Community: Array<FeeType>;
        Office: Array<FeeType>;
    }) => {
        const communityData = res.Community;
        projectFeatureData.value.community.Fee = communityData.slice(0, 2);
        // 判断是否是兜底方案
        if (communityData.length === 1 && Number(communityData[0].UUID) === 0) {
            projectFeatureData.value.community.Fee.push(communityData[0]);
        }
        projectFeatureOptions.value.community.Fee = communityData;
        const officeData = res.Office;
        projectFeatureData.value.office.Fee = officeData.slice(0, 2);
        // 判断是否是兜底方案
        // if (officeData.length === 1 && Number(officeData[0].UUID) === 0) {
        //     projectFeatureData.value.office.Fee.push(officeData[0]);
        // }
        projectFeatureOptions.value.office.Fee = officeData;
    });
    featurePlan.getOfficeFeaturePlan((res: Array<PlanType>) => {
        projectFeatureData.value.office.Plan = res.slice(0, 2);
        projectFeatureOptions.value.office.Plan = res;
    });
    return {
        projectFeatureData
    };
}

function getDisFormData(value: FormData | false = false) {
    let formData: FormData = {
        Account: '',
        Role: '1',
        Community: '',
        Email: '',
        Phone: '',
        TimeZone: timeZone.getManageDefaultTimeZone(),
        Language: Lang,
        ChargeMode: '0',
        Confusion: '',
        SipType: '3',
        Info: '',
        IsEncryptPin: '0',
        IsVillaMonitor: '1',
        ProjectFeature: projectFeatureData.value,
        ProjectFeatureOptions: projectFeatureOptions.value
    };

    if (isValidFormData(value)) {
        formData = { ...value };
        formData.ProjectFeatureOptions = projectFeatureOptions.value;
        // 修改时列表值是Location而不是Community
        if (formData.Location) formData.Community = formData.Location;
    }

    return deepCopyObject(formData);
}

// '添加修改'的对话框相关数据
const isShowDisDialog = ref(false);
const disDialogOperaType: Ref<OperateType> = ref('add');
const formData = ref(getDisFormData());
function addDistributor() {
    isShowDisDialog.value = true;
    formData.value = getDisFormData();
    disDialogOperaType.value = 'add';
}

function editDistributor(value: FormData) {
    isShowDisDialog.value = true;
    formData.value = getDisFormData(value);
    disDialogOperaType.value = 'edit';
}

const changePath = (key: string, path: string) => {
    router.push(path);
};

// 以下为切换类型，列表界面需要渲染的不同数据

type UserType = 'distributor' | 'installer' | 'endUser' | 'officeUser' | 'property';
// list header 数据
const headerDis: Array<ListHeaderItem> = [{
    label: WordList.TabelResetPwInHtmlAccount,
    name: 'Account'
}, {
    label: WordList.TabelUserInHtmlCompany,
    name: 'Community'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}, {
    label: WordList.ProperAllTextMobileNumber,
    name: 'Phone'
}];

const headerIns: Array<ListHeaderItem> = [{
    label: WordList.TabelResetPwInHtmlAccount,
    name: 'Account'
}, {
    label: WordList.ProperAllTextRoleDistributor,
    name: 'Manager'
}, {
    label: WordList.TabelUserInHtmlCompany,
    name: 'Community'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}, {
    label: WordList.TabelPersonUserInHtmlPhone,
    name: 'Phone'
}];

const headerUser: Array<ListHeaderItem> = [{
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'SipAccount'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}, {
    label: WordList.ProperAllTextMobileNumber,
    name: 'MobileNumber'
}, {
    label: WordList.ProperAllTextInstaller,
    name: 'Install'
}, {
    label: WordList.ProperAllCommunity,
    name: 'Community'
}, {
    label: WordList.PersonuserFamilyMaster,
    name: 'FamilyMaster'
}, {
    label: WordList.TabelCommunitiesAddress,
    name: 'Address'
}, {
    label: WordList.TabelPersonUserInHtmlPhone,
    name: 'Phone'
}, {
    label: WordList.TabelMessageBoxCreateTime,
    name: 'CreateTime'
}, {
    label: WordList.TabelExpretimeBoxSpecificTime,
    name: 'ExpireTime'
}];

// 6.3 新增办公人员相关列表信息
const headersOfficeUser: Array<ListHeaderItem> = [{
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'SipAccount'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}, {
    label: WordList.ProperAllTextMobileNumber,
    name: 'MobileNumber'
}, {
    label: WordList.ProperAllTextInstaller,
    name: 'Install'
}, {
    label: WordList.ProperAllOffice,
    name: 'Office'
}, {
    label: WordList.TabelPersonUserInHtmlPhone,
    name: 'Phone'
}, {
    label: WordList.TabelMessageBoxCreateTime,
    name: 'CreateTime'
}, {
    label: WordList.TabelExpretimeBoxSpecificTime,
    name: 'ExpireTime'
}];

// 6.4 新增pm app列表信息
const headersPMUser: Array<ListHeaderItem> = [{
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}, {
    label: WordList.ProperAllTextInstaller,
    name: 'Installer'
}, {
    label: WordList.ProperAllTextProject,
    name: 'Project',
    type: 'customize'
}, {
    label: WordList.CreateTime,
    name: 'CreateTime'
}];

type SearchKey = Array<{
    name: string;
    label: string;
}>;

const disSearchKey = [{
    label: WordList.TabelResetPwInHtmlAccount,
    name: 'Account'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}];

const installerSearchKey = disSearchKey;
const endUserSearchKey = [{
    name: 'Sip',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Community',
    label: WordList.ProperAllCommunity
}, {
    name: 'FamilyMaster',
    label: WordList.PersonuserFamilyMaster
}];
const officeUserSearchKey = [{
    name: 'Sip',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Name',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}, {
    name: 'MobileNumber',
    label: WordList.ProperAllTextMobileNumber
}, {
    name: 'Install',
    label: WordList.ProperAllTextInstaller
}, {
    name: 'Office',
    label: WordList.ProperAllOffice
}];

type UserPageData = {
    [key in UserType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        searchKey: SearchKey;
        defaultAction: Array<ActionType>;
        deleteUrl: string;
    }
};

const getUserPageData = () => {
    // filter 数据
    const disRequestData: RequestData = {
        url: 'v3/web/common/distributor/getList',
        param: {
            searchKey: 'Account',
            searchValue: ''
        }
    };

    const installerRequestData: RequestData = {
        url: 'v3/web/common/installer/getList',
        param: {
            searchKey: 'Account',
            searchValue: ''
        }
    };

    const endUserRequestData: RequestData = {
        url: 'getallpersonaluser',
        param: {
            searchKey: 'Sip',
            searchValue: ''
        }
    };

    // 6.3 新增请求办公用户
    const officeUserRequestData: RequestData = {
        url: 'v3/web/office/user/getOfficeUserList',
        param: {
            searchKey: 'Sip',
            searchValue: ''
        }
    };

    // 6.4 新增pm app用户
    const propertyUserRequestData: RequestData = {
        url: 'v3/web/common/account/getPmInfoListForSuper',
        param: {
            searchKey: 'Email',
            searchValue: '',
            InstallerId: 'all',
            ProjectId: 'all'
        }
    };

    // 类型分类
    const userPageData: UserPageData = {
        distributor: {
            header: headerDis,
            requestData: disRequestData,
            searchKey: disSearchKey,
            defaultAction: ['edit', 'delete'],
            deleteUrl: 'v3/web/common/distributor/delete'
        },
        installer: {
            header: headerIns,
            requestData: installerRequestData,
            searchKey: installerSearchKey,
            defaultAction: ['info', 'delete'],
            deleteUrl: 'v3/web/common/installer/delete'
        },
        endUser: {
            header: headerUser,
            requestData: endUserRequestData,
            searchKey: endUserSearchKey,
            defaultAction: ['info'],
            deleteUrl: ''
        },
        officeUser: {
            header: headersOfficeUser,
            requestData: officeUserRequestData,
            searchKey: officeUserSearchKey,
            defaultAction: ['info'],
            deleteUrl: ''
        },
        property: {
            header: headersPMUser,
            requestData: propertyUserRequestData,
            searchKey: [],
            defaultAction: ['info'],
            deleteUrl: ''
        }
    };

    return userPageData;
};

const getOfficeInfoDevice = (id: string) => {
    const deviceHeaders: Array<ListHeaderItem> = [{
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }];
    const devRequestData: RequestData = {
        url: 'getaccountdev',
        param: {
            ID: id
        }
    };
    return {
        deviceHeaders,
        devRequestData
    };
};

export {
    getMenus,
    getDisFormData,
    addDistributor,
    editDistributor,
    changePath,
    UserType,
    SearchKey,
    getUserPageData,
    isShowDisDialog,
    disDialogOperaType,
    formData,
    getInitPlan,
    getOfficeInfoDevice
};
